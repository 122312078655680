<template>
    <div v-if="value.children.length > 0" class="megamenu" @mouseover="$emit('mouseover')" @mouseleave="$emit('mouseleave')">
        <div :class="'grid-container full inner bg ' + value.bgclass">
            <div class="grid-container" style="position: relative;">
            <button class="button close" aria-label="close menu" v-on:click="$emit('closemenu')"></button> 
            <div v-if="value.template == 'icon-list'">
                <mega-menu-icon v-on:setactivelink="" v-model="value.children"></mega-menu-icon>
            </div>
            <div v-else class="grid-x grid-padding-x">
                <div class="large-5 cell">
                    <span class="megatitle megaheader"><a :href="value.url">{{ value.title }}</a></span> 
                    <div class="grid-x grid-padding-x">
                        <div class="large-6 cell">
                      
                            <mega-menu-item v-model="value.children" v-on:setactivelink="activelink=$event" v-on:removeactivelink="activelink=null" :activelink="activelink"></mega-menu-item>
                        </div>
                        <div class="large-6 cell">
                            <mega-menu-item v-if="activelink != null" v-on:setactivelink="" v-model="value.children[activelink].grandchildren"></mega-menu-item>
                        </div>                    
                    </div>
                </div>
                <template v-if="value.filters">
                    <div class="large-4 cell">
                        <span class="megatitle megaheader">shop by</span> 
                        <div class="grid-x grid-padding-x">
                            <div class="large-6 cell">
                                <mega-menu-filter v-model="value.filters" :level="0" v-on:setactivelink="activefilter=$event" v-on:removeactivelink="activefilter=null" :activelink="activelink"></mega-menu-filter>
                            </div>
                            <div class="large-6 cell">
                                <mega-menu-filter v-if="activefilter != null" :level="1" v-on:setactivelink="" v-model="value.filters[activefilter]"></mega-menu-filter>
                            </div>                    
                        </div>
                    </div>
                    <div class="large-3 cell">
                        <span class="megatitle megaheader">Inspiration</span>
                        <ul class="no-bullet">
                            <li><a href="/inspiration/brochure-request">Request a Brochure</a></li>
                            <li><a href="/blog">The Alternative Blog</a></li>
                            <li><a href="/blog">Floor Gazing</a></li>
                        </ul>
                    </div>
                </template>
                <div v-else class="large-7 cell">
                    <span class="megatitle">handy quick links</span> 
                    <div v-if="activelink != null" class="grid-x grid-padding-x inspire">
                        <div class="large-6 xlarge-4 cell" v-for="(tip, index) in value.children[activelink].tips">
                            <mega-menu-tip :value="tip"></mega-menu-tip>
                        </div>
                    </div>
                    <div v-else class="grid-x grid-padding-x inspire">
                        <div class="large-6 xlarge-4 cell" v-for="(tip, index) in value.tips">
                            <mega-menu-tip :value="tip"></mega-menu-tip>
                        </div>
                    </div>                    
                  
                </div>
            </div>
            </div>
        </div>
    </div>
</template>
<script>
	import megaMenuIcon from './mega-menu-icon';
	import megaMenuTip from './mega-menu-tip';
	import megaMenuItem from './mega-menu-item';
    import megaMenuFilter from './mega-menu-filter';

	export default {
        props: ['value'],
        components: {
            megaMenuIcon,
            megaMenuTip,
            megaMenuItem,
            megaMenuFilter
        },
        data: function () {
            return {
                activelink: null,
                activefilter: null
            }
        }
	}
</script>

<template>
    <ul class="vertical menu">
        <li v-for="(child, index) in Object.keys(value)">
            <a v-if="level == 0" href="#" @mouseover="setactivelink(child)" @mouseout="removeactivelink(child)" 
            v-bind:class="[{current : activelink == child}, {parent: true}]"
            >
                {{ child }}
            </a>
            <a v-else :href="value[child]" @mouseover="setactivelink(child)" @mouseout="removeactivelink(child)" 
            v-bind:class="[{current : activelink == child}, {parent: false}]"
            >
                {{ child }}
            </a>
        </li>
    </ul>
</template>
<script>
	export default {
		props: ['value', 'level'],
        data: function () {
            return {
                activelink: null
            }
        },
        methods: {
            setactivelink : function(event) {
                this.activelink = event;
                this.$emit('setactivelink', event);
            },
            removeactivelink: function(event) {
                this.activelink = null;
                this.$emit('setactivelink', event);
            }
        }
	}
</script>